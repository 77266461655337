.addbookmark {
  width: 400px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
}

.addbookmark__buttons {
  margin-top: 5px;
  padding: 10px;
  text-align: right;
}

.addbookmark__form input, .addbookmark__form textarea, .addbookmark__form label {
  display: block;
  margin-top: 5px;
}

.addbookmark__form label {
  text-align: left;
  font-size: 0.9em;
}