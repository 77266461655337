.fab {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #5399FF;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: solid 1px #0049B2;
}

.fab:hover {
  box-shadow: 1px 1px 1px 1px #0049B2;
  border: solid 1px #0049CC;
}