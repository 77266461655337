.bookmark {
  padding: 5px;
  border-top: solid 1px rgba(0,0,0,0.2);
}

.bookmark__row {
  display: flex;
  justify-content: space-between;
}

.bookmark__description {
  text-align: left;
  font-size: 0.95em;
}