body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  position: relative;
  height: 100%;
}

.addbookmark {
  width: 400px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
}

.addbookmark__buttons {
  margin-top: 5px;
  padding: 10px;
  text-align: right;
}

.addbookmark__form input, .addbookmark__form textarea, .addbookmark__form label {
  display: block;
  margin-top: 5px;
}

.addbookmark__form label {
  text-align: left;
  font-size: 0.9em;
}
.bookmarkApp {
  width: 500px;
  margin: 10px auto;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
  padding-top: 5px;
}

.bookmark {
  padding: 5px;
  border-top: solid 1px rgba(0,0,0,0.2);
}

.bookmark__row {
  display: flex;
  justify-content: space-between;
}

.bookmark__description {
  text-align: left;
  font-size: 0.95em;
}
.rating {
  color: goldenrod;
}
.fab {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #5399FF;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: solid 1px #0049B2;
}

.fab:hover {
  box-shadow: 1px 1px 1px 1px #0049B2;
  border: solid 1px #0049CC;
}
